import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Modal,
  Row,
  Form,
  Typography,
  Select,
  notification,
} from "antd";

import { getEmailTemplates, sendEmail, getTemplatePreview } from "../../services/commonController";
import { useState } from "react";
import { isEmpty } from "../../utils";

const { Text } = Typography;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function EmailModal(props: any) {

  const TAG = "EmailModal: ";
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [previewLoader, setPreviewLoader] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState("");

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data: emailTemplateResponse } = useQuery({ queryKey: ["email-templates"], queryFn: getEmailTemplates });

  const { mutate, isPending } = useMutation({
    mutationKey: ["send-email"],
    mutationFn: sendEmail,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: "Success", description: res.data.message });
        form.resetFields();
        setSubmittable(false);
        props.setIsEmailModalOpen(false);
        queryClient.invalidateQueries({ queryKey: ["email-logs"] });
        queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
      form.resetFields();
      setSubmittable(false);
    },
  });

  async function onTemplateSelect(value: any) {

    setPreviewLoader(true);
    // console.log(TAG + " preview api called ");
    try {
      const previewApiRes = await getTemplatePreview({ caseId: props.caseId, tempId: value });
      // console.log(TAG + " preview api response ", previewApiRes);
      setPreviewData(isEmpty(previewApiRes?.data?.data) == true ? "" : previewApiRes?.data?.data);
    } catch (error: any) {
      setPreviewData("");
      notification.error({ message: "Error", description: (error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong! while fetching email preview.") });
    }
    setPreviewLoader(false);

    setSubmittable(true);

  }


  // console.log(TAG + "previewData ", previewData);
  
  return (
    <Modal
      width={800}
      open={props.open}
      footer={null}
      onCancel={() => props.setIsEmailModalOpen(false)}
    >
      <Card title="Send Email">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) =>
            // console.log(TAG + "values ", values)
            mutate({ caseId: props.caseId, tempId: values.emailTemplate })
          }
        >
          <Form.Item
            name="emailTemplate"
            label={<Text className="font-bold">Email Templates</Text>}
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value, option) => onTemplateSelect(value)}
              disabled={previewLoader}
            >
              {emailTemplateResponse?.data?.map(({ name, id }: any) => {
                return (
                  <Option key={id} value={id}> {name} </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row className="flex justify-end pt-4">
            <Button
              htmlType="submit"
              type="primary"
              disabled={isPending || !submittable || previewLoader}
              loading={isPending}
            >
              Send Email
            </Button>
          </Row>
        </Form>

        <div className="mt-5"
          style={{
            position: "relative"
          }}
        >
          <div className=""
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: previewData }} />
        </div>

      </Card>
    </Modal>
  );
}

import { Skeleton } from "antd";
import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import { clearAxiosConfig, setAxiosConfig } from "../../services/axiosConfig";
import { clearStorageOnLogout, storeUserData } from "../../utils";
import { useGlobalStore } from "../StoreContext";

type AuthContextType = {
  isAuthenticated: any;
  processLogin: any;
  processLogout: any;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { dispatch } = useGlobalStore();
  const checkTokenExpiry = () => {
    const expiryTime = localStorage.getItem("expiryTime");
    const currentTime = new Date().getTime();
    if (expiryTime && Number(expiryTime) - currentTime <= 0) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      if (checkTokenExpiry()) {
        setIsAuthenticated(false);
        processLogout();
      } else {
        setIsAuthenticated(true);
      }
    }

    setLoading(false);
  }, []);

  const processLogin = (data: any) => {
    const expiryTime = new Date().getTime() + Number(data.expires_in) * 1000;
    localStorage.setItem("expiryTime", expiryTime.toString());

    storeUserData(data);
    setAxiosConfig();
    setIsAuthenticated(true);
  };

  const processLogout = () => {
    clearAxiosConfig();
    clearStorageOnLogout();
    setIsAuthenticated(false);
    dispatch({ type: "RESET_STORE" });
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, processLogin, processLogout }}
    >
      {loading ? (
        <div className={"h-screen flex flex-col items-center justify-center"}>
          <Skeleton
            loading={loading}
            round
            className="w-80 md:w-full mx-auto pl-10 pr-10"
            paragraph={{ rows: 15 }}
          ></Skeleton>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };

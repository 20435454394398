import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button, Col, Form, Input, Row, notification, Typography } from "antd";

import { useAuth } from "../../contexts/AuthContext";
import { authenticateUser } from "../../services/auth";
import { logoFullBlack } from "../../utils/image";

import { AUTH_LOGIN_ENDPOINT } from "../../constants/endpoints";

const Login = () => {

  const TAG = "Login: ";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { processLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isLoginBtnDisabled: boolean = !(username?.length && password?.length);

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const loginHandler = async (values: any) => {
    localStorage.clear();
    sessionStorage.clear();
    form.validateFields();
    const loginPayload = {
      password: values?.password,
      email: values?.username,
    };

    const loginAction = `${process.env.REACT_APP_API_BASE_URL}${AUTH_LOGIN_ENDPOINT}`;
    const res: any = fetch(loginAction, {
      "headers": {
        "accept": "application/json, text/plain, */*",
        "content-type": "application/json",
      },
      "body": JSON.stringify(loginPayload),
      "method": "POST"
    })
      .then(async (res: any) => {
        // console.log(TAG + "login res", res);
        const resRet = await res.json();
        // console.log(TAG + "res.json()", resRet);
        if (res?.status == 500) {
          notification.error({ message: "Failed", description: resRet?.message });
        }
        return resRet;
      })
      .catch((error: any) => {
        console.log(TAG + "login error", error);
      });

    return res;

  };

  const { mutate, isPending } = useMutation({
    mutationKey: ["login"],
    mutationFn: loginHandler,
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200) {
        if (res.data.temporary) {
          processLogin(res.data);
          navigate("/update-password");
        } else {
          processLogin(res.data);
        }
      }
    },
    onError: (error: any) => {
      console.log(TAG + "error with usemutation", error);
      if (error.message === "Network Error") {
        notification.error({ message: "Error", description: "Something went wrong. Please contact the administration." });
      } else {
        // notification.error({ message: "Failed", description: error?.response?.data?.message });
        notification.error({ message: "Failed", description: "Invalid username or password!" });
      }
    },
  });

  const handleForgotPasswordClick = () => navigate("/forgot-password");

  return (
    <>
      <div className="login-page flex h-screen items-center justify-center p-4">
        <div className="w-full max-w-md">
          <Col className="bg-white shadow-md rounded-md p-8 flex flex-col gap-6">
            <div className="flex justify-center">
              <img
                src={logoFullBlack}
                alt="loading..."
                style={{ width: "10rem" }}
              />
            </div>
            <Form
              form={form}
              initialValues={{ remember: true }}
              onFinish={(values) => mutate(values)}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username or email",
                  },
                ]}
              >
                <Input
                  onChange={onUsernameChange}
                  className="h-10"
                  placeholder="Enter your username or email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password
                  onChange={onPasswordChange}
                  placeholder="Enter your password"
                  className="h-10"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={isPending}
                  htmlType="submit"
                  disabled={isLoginBtnDisabled || isPending}
                  className="h-10 w-full text-black border-1 border-gray-300"
                  type="primary"
                >
                  Login
                </Button>
                <Row className="flex justify-center  pt-2">
                  <Typography.Text
                    className="cursor-pointer hover:underline text-blue-500"
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot Password?
                  </Typography.Text>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Login;

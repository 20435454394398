import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Typography, notification } from "antd";
import { useMutation } from "@tanstack/react-query";

import { forgotPassword } from "../../services/auth";

import { logoFullBlack } from "../../utils/image";

export const ForgotPassword = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const navigate = useNavigate();
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const { mutate } = useMutation({
    mutationFn: forgotPassword,
    mutationKey: ["forgot-password"],
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.message,
        });
        form.resetFields();
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Failed",
        description: error.response.data.message,
      });
      form.resetFields();
    },
  });
  const handleSubmit = () => {
    const email = form.getFieldValue("email");
    mutate(email);
  };

  const navigateToLoginPage = () => navigate("/login");
  return (
    <div className="login-page flex h-screen items-center justify-center p-4">
      <div className="w-full max-w-md">
        <Col className="bg-white shadow-md rounded-md p-8 flex flex-col gap-6">
          <div className="flex justify-center">
            <img
              src={logoFullBlack}
              alt="loading..."
              style={{ width: "10rem" }}
            />
          </div>
          <Form
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                { type: "email", message: "Please enter a valid email id." },
              ]}
            >
              <Input
                className="h-10"
                placeholder="Enter your email"
                allowClear
              />
            </Form.Item>

            <Form.Item>
              <Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!submittable}
                  className="w-full"
                >
                  Submit
                </Button>
                <Row className="flex justify-center  pt-2 w-full">
                  <Typography.Text
                    className="cursor-pointer hover:underline text-blue-500"
                    onClick={navigateToLoginPage}
                  >
                    Back to Login
                  </Typography.Text>
                </Row>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </div>
    </div>
  );
};

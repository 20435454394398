import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, Col, List, Modal, Row } from "antd";
import { refreshEmailLogs } from "../../services/commonController";

export const CountModal = (props: any) => {
  const { open, handleCancel, caseId, emailId, countType } = props;
  const [listData, setListData] = useState([]);
  const { data: res, isPending } = useQuery({
    queryKey: ["email-info", emailId],
    queryFn: () => refreshEmailLogs({ caseId, emailId }),
    enabled: !!emailId,
  });

  useEffect(() => {
    if (res?.status === 200) {
      if (countType === "Click") {
        setListData(res.data.data.clicks_detail || []);
      }
      if (countType === "Open") {
        setListData(res.data.data.opens_detail || []);
      }
    }
  }, [countType, res]);

  return (
    <Modal
      open={open}
      confirmLoading={isPending}
      onCancel={handleCancel}
      footer={null}
    >
      <Card title={`${countType} Count`}>
        <List
          loading={isPending}
          bordered
          dataSource={listData}
          renderItem={(item: any) => {
            const date = dayjs.unix(item?.ts);
            const formattedDate = date.format("MMMM DD YYYY h:mm A");
            return (
              <List.Item>
                <Row className="flex-row">
                  <Col>{formattedDate}</Col>
                  <Col>Client : {item?.ua}</Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </Card>
    </Modal>
  );
};

import { useState } from "react";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Input, Row, Spin, Typography, notification } from "antd";

import { postCaseComment } from "../../services/commonController";
import { postAdmissionStatus } from "../../services/agentController";
import { getUserData } from "../../utils";

export const AdmissionStatusAndCommentRow = ({ agentCaseDetails, isActionValid }: any) => {
  const TAG = "AdmissionStatusAndCommentRow: ";
  const [isAddingAdmissionStatus, setIsAddingAdmissionStatus] = useState(false);
  const [admissionStatus, setAdmissionStatus] = useState(
    agentCaseDetails?.admissionStatus
  );
  const [comment, setComment] = useState(agentCaseDetails?.comment);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: handleAdmissionStatusSubmission, isPending } = useMutation({
    mutationKey: ["admission-status"],
    mutationFn: postAdmissionStatus,
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.message === "Success" ? "Loan Acceptance updated successfully." : res.data.message,
        });
        setIsAddingAdmissionStatus(false);
        queryClient.invalidateQueries({
          queryKey: ["case-details"],
        });
        queryClient.invalidateQueries({
          queryKey: ["audit-logs"],
        });
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Error",
        description: error.response.data.error,
      });
    },
  });
  const {
    mutate: handleCommentSubmission,
    isPending: isCommentApiCallPending,
  } = useMutation({
    mutationKey: ["save-comment"],
    mutationFn: () =>
      postCaseComment({ caseId: agentCaseDetails.key, comment }),
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200) {
        notification.success({
          message: "Success",
          description:
            res.data.message === "Success"
              ? "Comment updated successfully."
              : res.data.message,
        });
        setIsAddingComment(false);
        queryClient.invalidateQueries({
          queryKey: ["case-details"],
        });
        queryClient.invalidateQueries({
          queryKey: ["audit-logs"],
        });
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Error",
        description: error.response.data.error,
      });
    },
  });
  const isSaveBtnDisabled =
    isPending || admissionStatus === agentCaseDetails?.admissionStatus;
  const isCommentSaveBtnDisabled =
    isCommentApiCallPending || comment === agentCaseDetails?.comment;
  const userInfo = getUserData();
  const isUserAllowed =
    userInfo?.roleName === "Admin" || userInfo?.roleName === "Agent-Manager";

  // console.log(TAG + " isActionValid ", isActionValid);

  return (
    <Spin
      indicator={<LoadingOutlined />}
      spinning={isPending || isCommentApiCallPending}
    >
      <Row className="pl-2 justify-between">
        {isAddingAdmissionStatus ? (
          <Col className="flex gap-4 pt-4 pb-4 ">
            <Typography.Text className="font-bold">
              Loan Acceptance
            </Typography.Text>
            <Col style={{ width: "20rem" }}>
              <Input.TextArea
                value={admissionStatus}
                onChange={(e) => setAdmissionStatus(e.target.value)}
                placeholder="Did the borrower agree to the loan?"
                autoSize
              />
            </Col>{" "}
            <Button
              type="primary"
              onClick={() =>
                handleAdmissionStatusSubmission({
                  admissionStatus: admissionStatus || "",
                  caseId: agentCaseDetails?.key || "",
                })
              }
              disabled={isSaveBtnDisabled}
            >
              Save
            </Button>
          </Col>
        ) : (
          <Col className="flex gap-4 pt-4 pb-4">
            <Typography.Text className="font-bold">
              Loan Acceptance
            </Typography.Text>
            <Col style={{ width: "20rem" }} className="flex">
              <Input.TextArea
                autoSize
                disabled
                placeholder="Did the borrower agree to the loan?"
                value={agentCaseDetails?.admissionStatus}
              />
            </Col>{" "}
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                setIsAddingAdmissionStatus(true);
                setAdmissionStatus(agentCaseDetails?.admissionStatus);
              }}
              disabled={!isActionValid}
            />
          </Col>
        )}

        {isAddingComment ? (
          <Col className="flex gap-4 pt-4 pb-4 ">
            <Typography.Text className="font-bold">Comment by Manager</Typography.Text>
            <Col style={{ width: "20rem" }}>
              <Input.TextArea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                autoSize
              />
            </Col>{" "}
            <Button
              type="primary"
              onClick={() => handleCommentSubmission()}
              disabled={isCommentSaveBtnDisabled}
            >
              Save
            </Button>
          </Col>
        ) : (
          <Col className="flex gap-4 pt-4 pb-4">
            <Typography.Text className="font-bold">Comment by Manager</Typography.Text>
            <Col style={{ width: "20rem" }} className="flex">
              <Input.TextArea
                autoSize
                disabled
                value={agentCaseDetails?.comment}
              />
            </Col>{" "}
            <Button
              disabled={!!agentCaseDetails && !isUserAllowed || !isActionValid}
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                setIsAddingComment(true);
                setComment(agentCaseDetails?.comment);
              }}
            />
          </Col>
        )}
      </Row>
    </Spin>
  );
};

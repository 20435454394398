import {
  Button,
  Col,
  Pagination,
  Row,
  Skeleton,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useState } from "react";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import AppLayout from "../../components/Layout";
import AddUser from "../../components/CreateUserModal";
import EditUserModal from "../../components/EditUserModal";
import { getUsers, updateUserStatus } from "../../services/adminController";
import { getUserData } from "../../utils";

const { Text } = Typography;

export default function Admin() {
  const [open, setOpen] = useState(false);
  const [isEditingUserDetails, setIsEditingUserDetails] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const queryClient = useQueryClient();
  const userData = getUserData();

  const {
    data: users,
    isPending,
  } = useQuery({
    queryKey: ["users", pageSize, currentPageNumber],
    queryFn: () => getUsers(currentPageNumber, pageSize),
    placeholderData: keepPreviousData,
  });

  const { mutate: updateUserStatusHandler, isPending: isMutationPending } =
    useMutation({
      mutationFn: updateUserStatus,
      onSuccess: (res, variables, context) => {
        if (res.status === 200) {
          queryClient.invalidateQueries({ queryKey: ["users"] });
          notification.success({
            message: "Success",
            description: res.data.message,
          });
        }
      },
      onError: (error: any) => {
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      },
    });

  const extraField = (
    <Row className="flex justify-end  gap-4 items-center">
      <Text className="text-lg">Create User</Text>
      <Tooltip title="Create User">
        <Button
          onClick={() => setOpen(true)}
          className="h-10 w-12 flex items-center justify-center "
          icon={<PlusOutlined style={{ fontSize: "24px" }} />}
        />
      </Tooltip>
    </Row>
  );

  const columns: any = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      render: (item: any, records: any) => (
        <span>{`${records?.firstName} ${records?.lastName}`}</span>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNo",
      render: (mobile: string | number) => <span>{mobile || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "roleAssign",
      render: (role: string | string[]) => {
        return (
          <span>{Array.isArray(role) ? role.join(", ") : role || "-"}</span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (item: undefined, records: any) => {
        
        const { enabled, email, id } = records;
        const enable = enabled ? false : true;
        const disableLoggedInUserFromDisabling = records?.email === userData?.user_name;

        return (
          <>
            <span className="flex gap-2 items-center" key={id}>
              <Tooltip title="Edit User Details" >
                <Button
                  disabled={isPending || isMutationPending}
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setIsEditingUserDetails(true);
                    setUserInfo(records);
                  }}
                />
              </Tooltip>
              <Switch
                loading={isPending || isMutationPending}
                disabled={disableLoggedInUserFromDisabling}
                defaultChecked={enabled}
                className="soft-delte-toggle"
                onClick={() => updateUserStatusHandler({ email, enable })}
              />
            </span>
          </>
        );
      },
    },
  ];

  return (
    <AppLayout moduleName={"User Management"} extraField={extraField}>
      
      <AddUser open={open} setOpen={setOpen} />

      <EditUserModal
        open={isEditingUserDetails}
        setOpen={setIsEditingUserDetails}
        userInfo={userInfo}
      />

      <Col>
        <Table
          loading={isPending || isMutationPending}
          columns={columns}
          dataSource={users?.data?.data || []}
          size="middle"
          pagination={false}
        />
        <Row className="flex justify-center">
          <Pagination
            showSizeChanger
            onChange={(page, pageSize) => {
              setCurrentPageNumber(page);
              setPageSize(pageSize);
            }}
            total={users?.data?.totalResults}
            className="pt-6 pb-6"
          />
        </Row>
      </Col>
    </AppLayout>
  );
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tabs,
  Tooltip,
  Typography,
  notification,
  Skeleton
} from "antd";

import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EyeOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import AppLayout from "../Layout";
import { SmsModal } from "../SMS";
import { EmailModal } from "../Email";
import { LegalNotice } from "../LegalNotice";
import { WhatsAppModal } from "../WhatsApp";
import { BorrowerDetails } from "../BorrowerDetails";
import { CallResponseModal } from "../Call/CallResponseModal";
import { CallInProgressModal } from "../Call/CallInProgressModal";
import { checkServerHealth } from "../../services/public";

import {
  getAuditLogs,
  getCallLogs,
  getMailLogs,
  getRecordingUrl,
  getSMSLogs,
  getWhatsappLogs,
  refreshEmailLogs,
  judicialActionHit
} from "../../services/commonController";


import { delay } from "../../utils";
import { SWW } from "../../utils/message";
import { dpdStatus90 } from "../../constants/constants";
import { transferCaseToLegal, getAdminCaseDetails } from "../../services/adminController";
import { getAgentManagerCaseDetails } from "../../services/agentManagerController";


import {
  getAgentCaseDetails,
  initiateCall,
  markAsPaid,
  pastCallDetails
} from "../../services/agentController";

import { PlayAudioMpdal } from "../PlayAudioModal";
import { CountModal } from "../EmailLogs/CountModal";
import { decryptData, getUserData, isEmpty } from "../../utils";
import { useGlobalStore } from "../../contexts/StoreContext";
import { InitiateCallStatusModal } from "../InitiateCallStatusModal";
import { AdmissionStatusAndCommentRow } from "../AdmissionStatusAndCommentRow";
import { TranscriptModal } from "../TranscriptModal";

interface AgentCaseDetailsTypes {
  borrowerName: string;
  loanAmount: string;
  nbfc: string | null;
  dpdStatus: string;
  status: string;
  lastCallDate: string | null;
  processInstanceId: string | null;
  key: string | null;
  loanId: string;
  dueAmount: string;
  callingEnabled: boolean;
  callUuid: string;
  admissionStatus: string;
  nextPlannedAction: string | null;
  nextScheduledCall: string | null;
  sendToLegal: boolean;
  sendDemandNotice: boolean;
  sendLegalNotice: boolean;
  initiateJudicialAction: boolean;
  legalNoticeTempId: string | null;
  demandNoticeTempId: string | null;
}

const auditLogsColumns = [
  {
    title: "Action",
    dataIndex: "action",
  },
  {
    title: "Performed By",
    dataIndex: "performedBy",
  },
  {
    title: "Timestamp",
    dataIndex: "createdAt",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    render: (comment: string) => <span>{comment || "-"}</span>,
  },
];

export default function CaseDetails() {

  const TAG = "CaseDetails: ";
  const [callId, setCallId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [countType, setCountType] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [recordingUrl, setRecordingUrl] = useState("");
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isLegalModalOpen, setIsLegalModalOpen] = useState<boolean>(false);
  const [callInitiationData, setCallInitiationData] = useState({});
  const [isDownloadingAudio, setIsDownloadingAudio] = useState(false);
  const [isCountModalVisible, setIsCountModalVisible] = useState(false);
  const [isRecordingUrlFetched, setIsRecordingUrlFetched] = useState(true);
  const [openCallResponseModal, setOpenCallResponseModal] = useState(false);
  const [isWhatsAppModalVisible, setISWhatsAppModalVisible] = useState(false);
  const [openCallInProgressModal, setOpenCallInProgressModal] = useState(false);
  const [isPlayAudioModalVisible, setIsPlayAudioModalVisible] = useState(false);
  const [isCallStatusModalVisible, setIsCallStatusModalVisible] = useState(false);
  const [isTranscriptModalVisible, setIsTranscriptModalVisible] = useState(false);
  const [isActionValid, setIsActionValid] = useState(false);

  const { id, type } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userInfo = getUserData();

  const { state: { bucketName }, dispatch } = useGlobalStore();

  const caseDetailsType = type ? type : "";
  var paramBucketName: any = bucketName ? bucketName : caseDetailsType;
  let queryFunCustom: any;
  if (userInfo?.roleName == "Agent" || userInfo?.roleName == "Legal-Agent") queryFunCustom = getAgentCaseDetails(id || "", paramBucketName);
  if (userInfo?.roleName == "Agent-Manager" || userInfo?.roleName == "Legal-Manager") queryFunCustom = getAgentManagerCaseDetails(id || "", paramBucketName);
  if (userInfo?.roleName == "Admin") queryFunCustom = getAdminCaseDetails(id || "", paramBucketName);

  const { data: responseData, isSuccess, isError, isFetching, isPending: isCaseDetailsPending } = useQuery({
    queryKey: ["case-details"],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    enabled: true,
    // keepPreviousData: true,
    retry: 1,
    queryFn: () => getAgentCaseDetails(id || "", paramBucketName)
  });


  useEffect(() => {
    if (isEmpty(responseData?.data?.data) == false) {
      // console.log(TAG + " responseData?.data?.data?.bucketName ", responseData?.data?.data?.bucketName);
      setIsActionValid(responseData?.data?.data?.caseClosed === true ? false : true);
      if (responseData?.data?.data?.lastCallResponseCaptured === false) {
        handlePastCallDetails(id || "");
      }
      window.location.hash = "no-back-button";
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      }
    }

    if (isFetching === false && isSuccess === false) {
      navigate("/");
      notification.error({ message: "Error", description: "Something went wrong while fetching case details." });
    }

  }, [isFetching]);

  const [loadTable, setLoadTable] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (!isCaseDetailsPending) {
        setLoadTable(false);
      }
    }, 200);
  }, [isCaseDetailsPending]);

  const { mutate: handlePastCallDetails } = useMutation({
    mutationKey: ["past-call-details"],
    mutationFn: (caseId: string) => pastCallDetails(caseId),
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        setCallInitiationData(res.data.data);
        setOpenCallResponseModal(true);
        window.onbeforeunload = function () { return false; }
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.error });
    },
  });

  const { mutate: onClickJudicialAction } = useMutation({
    mutationFn: (caseId: string) => judicialActionHit(caseId),
    mutationKey: ["judicialActionKey"],
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200 && res?.message === "Success") {
        notification.success({ message: "Success", description: res?.message });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
      } else {
        notification.error({ message: "Error", description: res?.message ? res?.message : "Something went wrong while applying judicial action." });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error?.response?.data?.error });
      queryClient.invalidateQueries({ queryKey: ["case-details"] });
    },
  });


  const {
    data: callLogsResponse,
    isPending: isCallLogsPending,
    isRefetching: refetchingCallLogs,
  } = useQuery({
    queryKey: ["call-logs", selectedTab, currentPage, pageSize],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getCallLogs(id || "", currentPage, pageSize),
    enabled: selectedTab === 1,
  });

  const {
    data: smsLogsResponse,
    isPending: isSmsLogsPending,
    isRefetching: refetchingSmsLogs,
  } = useQuery({
    queryKey: ["sms-logs", selectedTab, currentPage, pageSize],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getSMSLogs(id || "", currentPage, pageSize),
    enabled: selectedTab === 2,
  });

  const {
    data: mailLogsResponse,
    isPending: isMailLogsPending,
    isRefetching: refetchingEmailLogs,
  } = useQuery({
    queryKey: ["email-logs", selectedTab, currentPage, pageSize],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getMailLogs(id || "", currentPage, pageSize),
    enabled: selectedTab === 3,
  });

  const {
    data: whatsappLogsResponse,
    isPending: isWhatsappLogsPending,
    isRefetching: refetchingWhatsappLogs,
  } = useQuery({
    queryKey: ["Whatsapp-logs", selectedTab, currentPage, pageSize],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getWhatsappLogs(id || "", currentPage, pageSize),
    enabled: selectedTab === 4,
  });

  const { mutate: handleCallInitiation } = useMutation({
    mutationKey: ["initiate-call"],
    mutationFn: initiateCall,
    onSuccess: async (res, variables, context) => {
      if (res.data.status === 200) {
        if (res.data.data.status === "Connected") {
          setCallInitiationData(res.data.data);
          setOpenCallInProgressModal(false);
          setOpenCallResponseModal(true);
        } else {
          setOpenCallInProgressModal(false);
          setIsCallStatusModalVisible(true);
          setCallStatus(res.data.data.status);
        }
        queryClient.invalidateQueries({ queryKey: ["call-logs"] });
        await delay(400);
        await queryClient.invalidateQueries({ queryKey: ["case-details"] });
      }
    },
    onError: (error: any) => {
      setOpenCallInProgressModal(false);
      notification.error({ message: "Error", description: error.response.data.error });
    },
  });

  const {
    data: auditLogsResponse,
    isPending: isAuditLogsPending,
    isRefetching: refetchingAuditLogs,
  } = useQuery({
    queryKey: ["audit-logs", selectedTab, currentPage, pageSize],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getAuditLogs(id || "", currentPage, pageSize),
    enabled: selectedTab === 5,
  });

  const { mutate: handleEmailLogRefresh, isPending: isEmailLogsRefreshing } =
    useMutation({
      mutationKey: ["refresh-email-logs"],
      mutationFn: refreshEmailLogs,
      onSuccess: (res, variables, context) => {
        if (res.data.status === 200) {
          queryClient.invalidateQueries({
            queryKey: ["email-logs"],
          });
        }
      },
    });

  const handlePhoneIconClick = () => {
    setOpenCallInProgressModal(true);
    handleCallInitiation(id || "");
    window.onbeforeunload = function () { return false; }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const agentCaseDetails: AgentCaseDetailsTypes | undefined = responseData?.data?.data;

  const totalRecordsForPagination =
    callLogsResponse?.data?.totalResults ||
    smsLogsResponse?.data?.totalResults ||
    whatsappLogsResponse?.data?.totalResults ||
    auditLogsResponse?.data?.totalResults ||
    mailLogsResponse?.data?.totalResults;

  const isAuditLogsTableVisible = userInfo?.roleName === "Admin" || userInfo?.roleName === "Agent-Manager" || userInfo?.roleName === "Legal-Manager";
  const hideTransferToLegal = userInfo?.roleName === "Legal-Manager" || userInfo?.roleName === "Legal-Agent";
  const hideJudicialBtn = userInfo?.roleName === "Agent" || userInfo?.roleName === "Agent-Manager";
  const hideDemantAndLegalButton = userInfo?.roleName === "Agent" || userInfo?.roleName === "Agent-Manager";

  const { data: recordingUrlResponse } = useQuery({
    queryKey: ["get-recordingUrl", callId],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getRecordingUrl({ callId, caseId: id || "" }),
    enabled: callId?.length > 0,
  });

  const { mutate: fetchRecordingUrl } = useMutation({
    mutationKey: ["get-recordingUrl"],
    mutationFn: getRecordingUrl,
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        setIsPlayAudioModalVisible(true);
        setIsRecordingUrlFetched(true);
        setRecordingUrl(data.data.data);
      }
    },
  });

  const emailTableColumns = [
    {
      key: 1,
      title: "Email Date",
      dataIndex: "communicationDate",
    },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      render: (response: string) => (
        <span>{response || <Tooltip title="-">-</Tooltip>}</span>
      )
    },
    {
      key: 2,
      title: "Template Sent",
      dataIndex: "templateSent",
    },
    {
      key: 3,
      title: "Status",
      dataIndex: "status",
    },
    {
      key: 4,
      title: "Click Count",
      dataIndex: "clickCount",
      render: (count: number | null, record: any) => (
        <Button
          type="link"
          disabled={count === 0 || !count}
          onClick={() => {
            handleCountModalVisibility();
            setEmailId(record?.emailUuid);
            setCountType("Click");
          }}
        >
          {" "}
          {count || 0}
        </Button>
      ),
    },
    {
      key: 5,
      title: "Open Count",
      dataIndex: "openCount",
      render: (count: number | null, record: any) => (
        <Button
          type="link"
          disabled={count === 0 || !count}
          onClick={() => {
            handleCountModalVisibility();
            setEmailId(record?.emailUuid);
            setCountType("Open");
          }}
        >
          {" "}
          {count || 0}
        </Button>
      ),
    },
    {
      key: 6,
      title: "Refresh",
      render: (item: any, records: any) => {
        return (
          <Col key={records.key}>
            <Button
              key={records?.key}
              disabled={isEmailLogsRefreshing}
              onClick={() =>
                handleEmailLogRefresh({
                  caseId: id,
                  emailId: records?.emailUuid,
                })
              }
              icon={<ReloadOutlined />}
            ></Button>
          </Col>
        );
      },
    },
  ];

  const callTableColumn: any = [
    { title: "Call Date", dataIndex: "callDate" },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      render: (response: string) => (
        <span>{response || <Tooltip title="-">-</Tooltip>}</span>
      )
    },
    {
      title: "Call Start Time",
      dataIndex: "callFrom",
      defaultSortOrder: "descend"
    },
    {
      title: "Call End Time",
      dataIndex: "callTo"
    },
    {
      title: "Call Response Log",
      dataIndex: "response",
      render: (response: string) => (
        <span>{response || <Tooltip title="Not Available">N/A</Tooltip>}</span>
      )
    },
    {
      title: "Call Recording",
      dataIndex: "recordingUrl",
      render: (url: string, records: any) => {
        const isPlayIconDisabled = !records?.recordingAvailable;
        const cursorType = isPlayIconDisabled ? "not-allowed" : "pointer";
        return (
          <Col className="flex gap-5 ">
            <Col>
              <Tooltip
                title={
                  !isPlayIconDisabled
                    ? "Play recording!"
                    : "Recording is not available!"
                }
              >
                {isPlayIconDisabled ? (
                  <PlayCircleOutlined
                    style={{ color: "gray", cursor: cursorType }}
                    className="text-xl"
                  />
                ) : (
                  <div
                    style={{
                      cursor: cursorType,
                      color: isPlayIconDisabled ? "gray" : "#444444",
                    }}
                    className="text-xl"
                    onClick={() => {
                      setCallId(records?.callUuid);
                      setIsRecordingUrlFetched(false);
                      fetchRecordingUrl({
                        callId: records?.callUuid,
                        caseId: id || "",
                      });
                    }}
                  >
                    <PlayCircleOutlined />
                  </div>
                )}
              </Tooltip>
            </Col>

            <Col>
              <Tooltip
                title={
                  !isPlayIconDisabled
                    ? "Download recording!"
                    : "Recording is not available!"
                }
              >
                {isPlayIconDisabled ? (
                  <DownloadOutlined
                    style={{ color: "gray", cursor: cursorType }}
                    className="text-xl"
                  />
                ) : (
                  <div
                    style={{
                      cursor: cursorType,
                      color: isPlayIconDisabled ? "gray" : "#444444",
                    }}
                    className="text-xl"
                    // target="_"
                    onClick={() => {
                      setCallId(records?.callUuid);
                      setIsDownloadingAudio(true);
                    }}
                  >
                    <DownloadOutlined />
                  </div>
                )}
              </Tooltip>
            </Col>

            <Col>
              <Tooltip
                title={
                  !isPlayIconDisabled
                    ? "View Transript."
                    : "Transcript is not available."
                }
              >
                {isPlayIconDisabled ? (
                  <EyeOutlined
                    style={{ color: "gray", cursor: cursorType }}
                    className="text-xl"
                  />
                ) : (
                  <div
                    style={{
                      cursor: cursorType,
                      color: isPlayIconDisabled ? "gray" : "#444444",
                    }}
                    className="text-xl"
                    onClick={() => {
                      setCallId(records?.callUuid);
                      setIsTranscriptModalVisible(true);
                    }}
                  >
                    <EyeOutlined />
                  </div>
                )}
              </Tooltip>
            </Col>
          </Col>
        );
      }
    }
  ];

  const smsTableColumns = [
    {
      title: "SMS Date",
      dataIndex: "communicationDate"
    },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      render: (response: string) => (
        <span>{response || <Tooltip title="-">-</Tooltip>}</span>
      )
    },
    {
      title: "Template Sent",
      dataIndex: "templateSent"
    },
    {
      title: "Status",
      dataIndex: "status"
    },
  ];

  const whatsappColumns = [
    {
      title: "WhatsApp Date",
      dataIndex: "communicationDate"
    },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      render: (response: string) => (
        <span>{response || <Tooltip title="-">-</Tooltip>}</span>
      )
    },
    {
      title: "Template Sent",
      dataIndex: "templateSent"
    },
    {
      title: "Status",
      dataIndex: "status"
    },
  ];


  const items = [
    {
      label: "Call",
      key: "1",
      children: (
        <Table
          loading={isCallLogsPending || refetchingCallLogs}
          columns={callTableColumn}
          dataSource={callLogsResponse?.data?.data}
          size="middle"
          pagination={false}
        />
      ),
    },
    {
      label: "SMS",
      key: "2",
      children: (
        <Table
          loading={isSmsLogsPending || refetchingSmsLogs}
          columns={smsTableColumns}
          dataSource={smsLogsResponse?.data.data}
          size="middle"
          pagination={false}
        />
      ),
    },
    {
      label: "Email",
      key: "3",
      children: (
        <Table
          loading={isMailLogsPending || refetchingEmailLogs}
          columns={emailTableColumns}
          dataSource={mailLogsResponse?.data?.data}
          size="middle"
          pagination={false}
        />
      ),
    },
    {
      label: "Whatsapp",
      key: "4",
      children: (
        <Table
          loading={isWhatsappLogsPending || refetchingWhatsappLogs}
          columns={whatsappColumns}
          dataSource={whatsappLogsResponse?.data?.data}
          size="middle"
          pagination={false}
        />
      ),
    },
    ...(isAuditLogsTableVisible
      ? [
        {
          label: "Audit Logs",
          key: "5",
          children: (
            <Table
              loading={isAuditLogsPending || refetchingAuditLogs}
              columns={auditLogsColumns}
              dataSource={auditLogsResponse?.data?.data}
              size="middle"
              pagination={false}
            />
          ),
        },
      ]
      : []),
  ];

  const handlePlayAudioModalVisibility = () => {
    setIsPlayAudioModalVisible((prev) => !prev);
    setRecordingUrl("");
  };

  const isCallFeatEnabled = agentCaseDetails?.callingEnabled === undefined ? false : !agentCaseDetails?.callingEnabled;

  const handleInitiateCallStatusModalVisiblity = () => setIsCallStatusModalVisible((prev) => !prev);

  const borrowerName = decryptData(agentCaseDetails?.borrowerName);

  useEffect(() => {
    (async () => {
      if (callId?.length > 0 && isDownloadingAudio) {
        if (recordingUrlResponse?.data.status === 200) {
          const link = document.createElement("a");
          link.href = recordingUrlResponse.data.data;
          link.download = "recording.mp3";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(recordingUrlResponse.data.data);
        }
      }
    })();
    return () => {
      setCallId("");
      setIsDownloadingAudio(false);
    };
  }, [callId, recordingUrlResponse, isDownloadingAudio]);

  const handleCountModalVisibility = () => setIsCountModalVisible((pre) => !pre);

  // const { mutate: onClickMarkAsPaid } = useMutation({
  //   // mutationFn: (caseId: string) => markAsPaid(caseId),
  //   mutationFn: (caseId: string) => checkServerHealth(),
  //   mutationKey: ["markAsPaid"],
  //   onSuccess: (res: any, variables, context) => {
  //     if (res.status === 200) {
  //       // notification.success({ message: "Success", description: res.data.message === "Success" ? "Mark as paid successfully." : res.data.message });
  //       navigate("/");
  //       // queryClient.invalidateQueries({ queryKey: ["case-details"] });
  //       // queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
  //     }
  //   },
  //   onError: (error: any) => {
  //     notification.error({ message: "Error", description: error.response.data.error });
  //   },
  // });

  async function onClickMarkAsPaid(caseId: string) {
    try {
      // await checkServerHealth();
      await markAsPaid(caseId);
      // navigate("/");
      notification.success({ message: "Success", description: "Mark as paid successfully" });
      paramBucketName = "completed";
      localStorage.setItem("caseType", "completed");
      dispatch({ type: "SET_BUCKET_NAME", payload: "completed" });
      queryClient.invalidateQueries({ queryKey: ["case-details"] });
      queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
    } catch (error: any) {
      console.log(TAG + "error", error);
      notification.success({ message: "Error", description: "Something went wrong while marking as paid." });
    }
  }

  const { mutate: onClickTransferToLegal } = useMutation({
    mutationFn: (caseId: string) => transferCaseToLegal(caseId),
    mutationKey: ["transferToLegal"],
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200 && res?.message === "Success") {
        notification.success({ message: "Success", description: "Case transfer to legal." });
        navigate("/");
      } else {
        notification.error({ message: "Error", description: SWW });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.error });
    },
  });


  function transferLegalEvent(calledWith: any): void {
    onClickTransferToLegal(calledWith);
  }

  function sendDemandNotice(): void {
    // console.log(TAG + " demandNotice call ", calledWith);
    setIsLegalModalOpen(true);
  }

  function sendLegalNotice(): void {
    // console.log(TAG + " sendLegalNotice call ", calledWith);
    setIsLegalModalOpen(true);
  }

  function initiateJudicialAction(calledWith: any): void {
    // console.log(TAG + " initiateJudicialAction call ", calledWith);
    onClickJudicialAction(calledWith);
  }



  // console.log(TAG + " userInfo userInfo ", userInfo);
  // console.log(TAG + " case-details api call response ", responseData);
  // console.log(TAG + " case-details api isSuccess ", isSuccess);
  // console.log(TAG + " bucketName ", bucketName);
  // console.log(TAG + " url param id ", id);
  // console.log(TAG + " url param type ", type);

  // console.log(TAG + " agentCaseDetails ", agentCaseDetails);
  // console.log(TAG + " isActionValid ", isActionValid);

  // console.log(TAG + " pageSize ", pageSize);
  // console.log(TAG + " currentPage ", currentPage);
  // console.log(TAG + " totalRecordsForPagination ", totalRecordsForPagination);
  // console.log(TAG + " callInitiationData ", callInitiationData);

  return (
    <AppLayout>
      <Skeleton loading={loadTable} paragraph={{ rows: 15 }}>
        <Row className="flex justify-between pr-2">
          <Col className="flex gap-2">

            <Tooltip title="Go back to previous page">
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate("/")}
              />
            </Tooltip>

            <Col className="flex flex-col">
              <Typography.Text className="text-2xl font-bold">
                {borrowerName}
              </Typography.Text>
              <Typography.Text>{agentCaseDetails?.status}</Typography.Text>
            </Col>

          </Col>

          <Col>
            <Row className="flex justify-end">
              <Col className="flex gap-5">

                <Tooltip title="Call">
                  <Button
                    disabled={!isSuccess || isCallFeatEnabled || !isActionValid}
                    type="primary"
                    icon={<PhoneOutlined />}
                    className="communication-button"
                    onClick={handlePhoneIconClick}
                  />
                </Tooltip>

                <Tooltip title="Message">
                  <Button
                    disabled={!isSuccess || !isActionValid}
                    type="primary"
                    icon={<MessageOutlined />}
                    onClick={() => setIsSmsModalOpen(true)}
                  />
                </Tooltip>

                <Tooltip title="Email">
                  <Button
                    disabled={!isSuccess || !isActionValid}
                    type="primary"
                    icon={<MailOutlined />}
                    onClick={() => setIsEmailModalOpen(true)}
                  />
                </Tooltip>

                <Tooltip title="WhatsApp">
                  <Button
                    disabled={!isSuccess || !isActionValid}
                    type="primary"
                    icon={<WhatsAppOutlined />}
                    onClick={() => setISWhatsAppModalVisible(true)}
                  />
                </Tooltip>

              </Col>
            </Row>

            <Col className="mt-5 flex gap-2">

              <Button
                type="primary"
                onClick={() => onClickMarkAsPaid(id || "")}
                disabled={agentCaseDetails?.dueAmount !== "0" || !isActionValid}
              >Mark as Paid</Button>

              {!hideTransferToLegal &&
                <Button
                  type="primary"
                  disabled={agentCaseDetails?.sendToLegal === true ? false : true}
                  onClick={() => transferLegalEvent(id)}
                > Transfer to Legal</Button>
              }

              {agentCaseDetails?.dpdStatus === dpdStatus90 &&
                <>
                  {!hideDemantAndLegalButton &&
                    <>
                      <Button
                        type="primary"
                        disabled={agentCaseDetails?.sendDemandNotice === true ? false : true}
                        onClick={() => sendDemandNotice()}
                      > Send demand notice</Button>

                      <Button
                        type="primary"
                        disabled={agentCaseDetails?.sendLegalNotice === true ? false : true}
                        onClick={() => sendLegalNotice()}
                      > Send legal notice</Button>
                    </>
                  }

                  {!hideJudicialBtn &&
                    <Button
                      type="primary"
                      disabled={agentCaseDetails?.initiateJudicialAction === true ? false : true}
                      onClick={() => initiateJudicialAction(id)}
                    > Initiate Judicial Action</Button>
                  }
                </>
              }

            </Col>
          </Col>
        </Row>

        {bucketName == "completed" ? <div /> :
          <Row className="pl-2 justify-between">
            <Col className="flex gap-4 pt-4 pb-4">
              <Typography.Text className="font-bold">Next planned action</Typography.Text>{agentCaseDetails?.nextPlannedAction || "N/A"}
            </Col>
            <Col className="flex gap-4 pt-4 pb-4">
              <Typography.Text className="font-bold">Next scheduled call</Typography.Text>{agentCaseDetails?.nextScheduledCall || "N/A"}
            </Col>
          </Row>
        }

        <BorrowerDetails
          borrowerViewDetails={agentCaseDetails}
          isCaseDetailsPending={isCaseDetailsPending}
        />

        <AdmissionStatusAndCommentRow agentCaseDetails={agentCaseDetails} isActionValid={isActionValid} />

        <Tabs items={items} onChange={(e) => setSelectedTab(Number(e))} />

        <Row justify="center" className="pt-4">
          <Pagination
            total={totalRecordsForPagination}
            pageSize={pageSize}
            current={currentPage}
            showSizeChanger
            onChange={handlePaginationChange}
          />
        </Row>

        <InitiateCallStatusModal
          status={callStatus}
          isVisible={isCallStatusModalVisible}
          handleVisibility={handleInitiateCallStatusModalVisiblity}
        />

        <CallInProgressModal open={openCallInProgressModal} />

        <PlayAudioMpdal
          caseId={id || ""}
          callId={callId}
          recordingUrl={recordingUrl || ""}
          isVisible={isPlayAudioModalVisible}
          handleModalVisibility={handlePlayAudioModalVisibility}
        />

        <SmsModal
          open={isSmsModalOpen}
          setIsSmsModalOpen={setIsSmsModalOpen}
          caseId={agentCaseDetails?.key}
        />

        <EmailModal
          caseId={id}
          open={isEmailModalOpen}
          setIsEmailModalOpen={setIsEmailModalOpen}
        />

        {isLegalModalOpen === true &&
          <LegalNotice
            caseId={id}
            sendDemandNotice={agentCaseDetails?.sendDemandNotice}
            sendLegalNotice={agentCaseDetails?.sendLegalNotice}
            legalNoticeTempId={agentCaseDetails?.legalNoticeTempId}
            demandNoticeTempId={agentCaseDetails?.demandNoticeTempId}
            open={isLegalModalOpen}
            setIsLegalModalOpen={setIsLegalModalOpen}
          />
        }

        <WhatsAppModal
          caseId={id}
          open={isWhatsAppModalVisible}
          setIsWhatsAppModalVisible={setISWhatsAppModalVisible}
        />

        <CallResponseModal
          caseId={id}
          data={callInitiationData}
          open={openCallResponseModal}
          setOpenCallResponseModal={setOpenCallResponseModal}
          setLoadTable={setLoadTable}
        />

        <CountModal
          caseId={id}
          emailId={emailId}
          countType={countType}
          open={isCountModalVisible}
          handleCancel={handleCountModalVisibility}
        />

        <TranscriptModal
          isVisible={isTranscriptModalVisible}
          handleClose={() => setIsTranscriptModalVisible((pre) => !pre)}
          callId={callId}
          caseId={id}
        />
      </Skeleton>
    </AppLayout >
  );
}

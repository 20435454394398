import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Modal,
  Row,
  Form,
  Typography,
  Select,
  notification,
  Input
} from "antd";

import {
  getWhatsappTeplates,
  sendWhatsapp,
} from "../../services/commonController";

import { isEmpty } from "../../utils";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function WhatsAppModal(props: any) {

  const TAG = "WhatsAppModal: ";
  const [submittable, setSubmittable] = useState(false);
  const [templateMsg, setTemplateMsg] = useState("");
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<any>({});
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data: whatsappTemplatesResponse } = useQuery({ queryKey: ["whatsapp-templates"], queryFn: getWhatsappTeplates });

  const { mutate, isPending } = useMutation({
    mutationKey: ["send-sms"],
    mutationFn: sendWhatsapp,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: "Success", description: res.data.message });
        form.resetFields();
        setSubmittable(false);
        props.setIsWhatsAppModalVisible(false);
        queryClient.invalidateQueries({
          queryKey: ["Whatsapp-logs"],
        });
        queryClient.invalidateQueries({
          queryKey: ["audit-logs"],
        });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
      form.resetFields();
      setSubmittable(false);
    },
  });

  useEffect(() => {
    if (isEmpty(selectedTemplateOption) == false) {
      const findElem = whatsappTemplatesResponse?.data.find((element: any) => element.id == selectedTemplateOption?.key);
      setTemplateMsg(isEmpty(findElem) == true || isEmpty(findElem?.data) == true ? "" : findElem.data);
    }
  }, [selectedTemplateOption]);

  // console.log(TAG + " props ", props);
  // console.log(TAG + " whatsappTemplatesResponse ", whatsappTemplatesResponse);
  // console.log(TAG + " selectedTemplateOption ", selectedTemplateOption);
  // console.log(TAG + " templateMsg ", templateMsg);

  return (
    <Modal
      width={800}
      open={props.open}
      footer={null}
      onCancel={() => props.setIsWhatsAppModalVisible(false)}
    >
      <Card title="Send WhatsApp Message">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) =>
            // console.log(TAG + " values ", values)
            mutate({
              caseId: props.caseId,
              verticalId: values.whatsappTemplate,
              verticalName: selectedTemplateOption?.children,
            })
          }
        >
          <Form.Item
            name="whatsappTemplate"
            label={<Text className="font-bold">WhatsApp Templates</Text>}
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value, option) => {
                setSubmittable(true);
                setSelectedTemplateOption(option);
              }}
            >
              {whatsappTemplatesResponse?.data?.map(({ vertical, id }: any) => {
                return (
                  <Option key={id} value={id}>
                    {vertical}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {templateMsg ?
            <Form.Item
              name="templateBody"
              label={<Text className="font-bold">Preview</Text>}
            >
              <TextArea
                rows={7}
                placeholder={templateMsg}
                maxLength={20000}
                size="small"
                status=""
                variant="outlined"
                disabled={false}
                readOnly={true}
                value={templateMsg}
                className="text-dark"
              />
            </Form.Item>
            : <div />}

          <Row className="flex justify-end pt-4">
            <Button
              type="primary"
              disabled={!submittable || isPending}
              htmlType="submit"
            >
              Send Text
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}

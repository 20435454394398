import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  getAdminActiveCases,
  getAdminCompletedCases,
} from "../../services/adminController";

import AppLayout from "../Layout";
import { AdminCasesTable } from "./Tables/AdminCasesTable";
import { useGlobalStore } from "../../contexts/StoreContext";
import { getCasePayload } from "../../utils";

const AdminDashboard = () => {
  const TAG = "AdminDashboard: ";
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { state, dispatch } = useGlobalStore();
  const { activeTabKey } = state;

  const payload = getCasePayload(state);
  const {
    data: activeCases,
    isPending: isActiveCasesPending,
    isRefetching: isActiveCasesRefetching,
  } = useQuery({
    queryKey: [
      "agentAdminActiveCases",
      currentPageNumber,
      activeTabKey,
      payload,
    ],
    queryFn: () => getAdminActiveCases(payload),
    enabled: activeTabKey === "1",
    placeholderData: keepPreviousData,
  });

  const {
    data: completedCases,
    isPending: isCompletedCasesPending,
    isRefetching: isCompletedCasesRefetching,
  } = useQuery({
    queryKey: [
      "agentAdminCompletedCases",
      currentPageNumber,
      activeTabKey,
      payload,
    ],
    queryFn: () => getAdminCompletedCases(payload),
    enabled: activeTabKey === "2",
    placeholderData: keepPreviousData,
  });

  const items = [
    {
      label: "Active Cases",
      key: "1",
      children: (
        <AdminCasesTable
          isPending={isActiveCasesPending || isActiveCasesRefetching}
          totalRecords={activeCases?.totalResults}
          setCurrentPageNumber={setCurrentPageNumber}
          data={(activeCases?.data && activeCases?.data) || []}
          status_type="active"
        />
      ),
    },
    {
      label: "Resolved Cases",
      key: "2",
      children: (
        <AdminCasesTable
          isPending={isCompletedCasesPending || isCompletedCasesRefetching}
          totalRecords={completedCases?.totalResults}
          setCurrentPageNumber={setCurrentPageNumber}
          data={(completedCases?.data && completedCases?.data) || []}
          status_type="completed"
        />
      ),
    },
  ];

  const setActiveTabKey = (e: string) => {
    dispatch({ type: "SET_ACTIVE_TAB_KEY", payload: e });
  };

  // useEffect(() => {
  //   dispatch({ type: "SET_ACTIVE_TAB_KEY", payload: "2" });
  // }, [dispatch]);
  
  // console.log(TAG + "state.activeTabKey", state.activeTabKey);

  return (
    <AppLayout moduleName="Portfolio">
      <Tabs
        items={items}
        onChange={setActiveTabKey}
        activeKey={state.activeTabKey}
      ></Tabs>
    </AppLayout>
  );
};

export default AdminDashboard;

import React from "react";
import { Result, Button, Row } from "antd";
import { useNavigate } from "react-router-dom";

const Maintenance = () => {

  const navigate = useNavigate();
  const handleGoBackHome = () => navigate("/");
  
  return (
    <Row className="flex justify-center items-center h-full">
      <Result
        status="500"
        title="500"
        subTitle="Work in progress. Webside will be up soon"
        extra={
          <Button
            onClick={handleGoBackHome}
            type="primary"
            className="h-10  text-black border-1 border-gray-300"
          >
            Go Back Home
          </Button>
        }
      />
    </Row>
  );
};

export default Maintenance;

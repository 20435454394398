import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import LogRocket from 'logrocket';

import App from "./RootLayout";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setAxiosConfig } from "./services/axiosConfig";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalStateProvider } from "./contexts/StoreContext";
import router from "./routing/routes";


LogRocket.init('vqvtam/solveeasy', {
  dom: {
    isEnabled: true,
    textSanitizer: false, //all text elements will be obfuscated in sessions
    inputSanitizer: false, //LogRocket will not record user input from form elements
    privateAttributeBlocklist: ['data-hide-this'], // will not record: <div data-hide-this></div>
    privateClassNameBlocklist: ['class-hide-this'], // will not record: <div class="class-hide-this"></div>
    hiddenAttributes: ['hidden-value'], //  <div hidden-value="foo" shown-value="bar"></div> will be recorded as: <div shown-value="bar"></div>
  },
  console: {
    // isEnabled: true,
    isEnabled: {
      log: true,
      debug: true
    },
  },
  rootHostname: 'example.com', //Capture traffic from all subdomains under one session
  shouldCaptureIP: true, // capture ip address
  shouldDebugLog: false,
  mergeIframes: true, //Include iframes within the same session recording
  network: {
    isEnabled: true
  },
  browser: {
    urlSanitizer: url => {
      let sanitizedUrl = url;

      // redact the path following /ssn/ from the URL
      // sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^\/]*)/i, '/ssn/**redacted**');

      // redact the value of the query parameter secret_key
      // sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

      // make sure you return the sanitized URL string
      return sanitizedUrl;
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
setAxiosConfig();
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: { colorPrimary: "6F56A3" },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <GlobalStateProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </GlobalStateProvider>
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

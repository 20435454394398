

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Modal,
  Row,
  notification,
  Upload,
  Col,
  Spin
} from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';


import { getEmailTemplates, sendEmail, getTemplatePreview, sendDemandNoticeHit, sendLegalNoticeHit } from "../../services/commonController";
import { useEffect, useState } from "react";
import { isEmpty, delay } from "../../utils";

import type { GetProp, UploadFile, UploadProps } from 'antd';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


export function LegalNotice(props: any) {

  const queryClient = useQueryClient();
  const { sendDemandNotice, sendLegalNotice, legalNoticeTempId, demandNoticeTempId } = props;

  const templateId: string = sendDemandNotice === true ? demandNoticeTempId : legalNoticeTempId;
  const modalTitle: string = sendDemandNotice === true ? "Send demand notice" : "Send legal notice";
  const calledFor: string = sendDemandNotice === true ? "DEMAND_NOTICE" : "LEGAL_NOTICE";
  const actionUrl = `${process.env.REACT_APP_API_BASE_URL}sendLegalEmail?caseId=${props.caseId}&noticeType=${calledFor}`;

  const TAG = "LegalNotice: ";

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [previewData, setPreviewData] = useState("");




  const {
    data: emailTemplateResponse,
    isPending: isSmsLogsPending,
    isRefetching: refetchingSmsLogs,
    isFetching: isFetching,
    isError: isError
  } = useQuery({
    queryKey: ["email-templates-preview"],
    placeholderData: undefined,
    initialData: undefined,
    staleTime: 0,
    queryFn: () => getTemplatePreview({ caseId: props.caseId, tempId: templateId }),
    enabled: true
  });

  useEffect(() => {
    if (isFetching === false) {
      if (isError === true) {
        props.setIsLegalModalOpen(false);
        notification.error({ message: "Error", description: "Something went wrong while fetching email template." });
        onModalClose();
      } else {
        if (isEmpty(emailTemplateResponse?.data?.data) === true) {
          notification.error({ message: "Error", description: "No template data in response." });
          onModalClose();
        } else {
          setPreviewData(emailTemplateResponse?.data?.data);
        }
      }
    }
  }, [isFetching]);

  const fireNotice = async () => {
    console.log(TAG + "fireNotice got called");

    if (fileList.length > 1) {
      notification.error({ message: "Error", description: "Only one file is allowed." });
    }

    if (fileList.length == 1) {
      setUploading(true);
      // await delay(2000);
      // setUploading(false);
      if (sendDemandNotice === true) {
        onClickDemandNotice(props.caseId);
      }

      if (sendLegalNotice === true) {
        onClickLegalNotice(props.caseId);
      }

    }

  }

  const handleUpload = async () => {
    // console.log(TAG + "handleUpload got called");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as FileType);
    });
    fetch(actionUrl, {
      method: 'POST',
      body: formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      }
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        notification.success({ message: "Success", description: "Uploaded" });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
        onModalClose();
      })
      .catch(() => {
        notification.error({ message: "Error", description: "Something went wrong!" });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const uploadProps: UploadProps = {
    disabled: uploading,
    multiple: false,
    action: "#",
    accept: "xlsx, XLSX, xls, XLS",
    customRequest: () => { console.log(TAG + "custom request") },
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      // const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      // console.log(TAG + " file size ", file.size);

      //file size validation
      // if (file.size > 5e+6) {
      //   notification.error({ message: "Error", description: "File should be under 5mb." });
      //   return false;
      // }

      //file type validation
      // if (!isXLSX) {
      //   notification.error({ message: "Error", description: "File format not supported. Please upload an XLSX file." });
      // } else {
      //   setFileList([...fileList, file]);
      // }

      setFileList([...fileList, file]);
      return true;
    },
    fileList,
    maxCount: 1,
  };

  const { mutate: onClickDemandNotice } = useMutation({
    mutationFn: (caseId: string) => sendDemandNoticeHit(caseId),
    mutationKey: ["sendDemandNoticeKey"],
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200 && res?.message === "Success") {
        notification.success({ message: "Success", description: "Case send for demand notice." });
        handleUpload();
      } else {
        notification.error({ message: "Error", description: res?.message ? res?.message : "Something went wrong while sending demand notice." });
        onModalClose();
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error?.response?.data?.error });
      onModalClose();
    },
  });

  const { mutate: onClickLegalNotice } = useMutation({
    mutationFn: (caseId: string) => sendLegalNoticeHit(caseId),
    mutationKey: ["sendLegalNoticeKey"],
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200 && res?.message === "Success") {
        notification.success({ message: "Success", description: "Legal notice sent." });
        handleUpload();
      } else {
        notification.error({ message: "Error", description: res?.message ? res?.message : "Something went wrong while sending legal notice." });
        onModalClose();
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error?.response?.data?.error });
      onModalClose();
    },
  });

  function onModalClose() {
    setFileList([]);
    setUploading(false);
    setPreviewData("");
    props.setIsLegalModalOpen(false);
  }



  // console.log(TAG + "templateId ", templateId);
  // console.log(TAG + "modalTitle ", modalTitle);
  // console.log(TAG + "calledFor ", calledFor);
  // console.log(TAG + "actionUrl ", actionUrl);
  // console.log(TAG + "uploading ", uploading);
  // console.log(TAG + "fileList ", fileList);
  // console.log(TAG + "previewData ", previewData);
  // console.log(TAG + "emailTemplateResponse ", emailTemplateResponse);
  // console.log(TAG + "isFetching ", isFetching);
  // console.log(TAG + "isError ", isError);


  return (
    <Modal
      width={800}
      open={props.open}
      footer={null}
      onCancel={() => onModalClose()}
    >
      <Card title={modalTitle}>

        {isFetching === true ?
          <Spin />
          :
          <>
            <div>
              <Row justify="end" >
                <Col>
                  <Upload {...uploadProps}>
                    <Button shape="round" icon={<CloudUploadOutlined />} disabled={uploading} />
                  </Upload>
                </Col>
                <Col className="ms-2">
                  <Button
                    type="primary"
                    onClick={fireNotice}
                    disabled={fileList.length === 0}
                    loading={uploading}
                  >
                    {uploading ? 'Uploading' : modalTitle}
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="mt-5"
              style={{
                position: "relative"
              }}
            >
              <div className=""
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: previewData }} />
            </div>
          </>
        }
      </Card>
    </Modal>
  );
}

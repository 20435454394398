import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Form, Input, Row, Select, DatePicker } from "antd";

import {
  getClientList,
  getDpdStatus,
  getWorkflowStatus,
} from "../../services/commonController";
import { useGlobalStore } from "../../contexts/StoreContext";
import {
  disableFutureDate,
  getCasePayload,
  getCasePayloadType,
  validateAmount,
} from "../../utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

export function AdvanceFilter(props: any) {
  const TAG = "AdvanceFilter: ";
  const [form] = Form.useForm();
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(true);
  const [valuesForFilter, setValuesForFilter] = useState<any>({});
  const { state, dispatch } = useGlobalStore();

  const handleFormChange = () => setIsSubmitBtnDisabled(false);
  const handleFormReset = () => {
    form.resetFields();
    setIsSubmitBtnDisabled(true);
  };

  const { data } = useQuery({
    queryKey: ["client-list"],
    queryFn: getClientList,
  });

  const {
    nbfc,
    maxDueAmount,
    maxLoanAmount,
    minLoanAmount,
    minDueAmount,
    dpdStatus,
    status,
    lastCallDate,
  } = valuesForFilter;

  const startDate = lastCallDate ? lastCallDate[0]?.format("YYYY-MM-DD") : "";
  const endDate = lastCallDate ? lastCallDate[1]?.format("YYYY-MM-DD") : "";
  const defaultPayload = {
    dpdStatus: "",
    endDate: "",
    maxDueAmount: "",
    maxLoanAmount: "",
    minDueAmount: "",
    minLoanAmount: "",
    nbfc: "",
    page: 1,
    size: 10,
    startDate: "",
    status: "",
    sortOrder: "",
    sortBy: "",
  };

  const payload = {
    dpdStatus: dpdStatus?.label || "",
    startDate: startDate || "",
    endDate: endDate || "",
    maxDueAmount: maxDueAmount || "",
    maxLoanAmount: maxLoanAmount || "",
    minDueAmount: minDueAmount || "",
    minLoanAmount: minLoanAmount || "",
    nbfc: nbfc?.label || "",
    page: 1,
    size: 10,
    status: status?.label || "",
    sortOrder: "",
    sortBy: "",
  };

  const results = useQueries({
    queries: [
      { queryKey: ["dpdStatus"], queryFn: getDpdStatus, staleTime: Infinity },
      { queryKey: ["workflowStatus"], queryFn: getWorkflowStatus, staleTime: Infinity }
    ],
  });
  const dpdStatusResponse = results[0];
  const workflowStatusResponse = results[1];
  const dpdStatusList = dpdStatusResponse?.data?.data?.data || [];
  const workflowStatusList = workflowStatusResponse?.data?.data?.data || [];

  const initialPayload: any = getCasePayload(state);
  const initialNBFC = data?.clients?.find(
    (client: { clientName: string; clientId: string; fileUrl: string }) =>
      client.clientName === initialPayload.nbfc
  );
  const intialDpdStatus = dpdStatusList?.find(
    (status: { status: string }) => status?.status === initialPayload.dpdStatus
  );
  const intialWorkflowStatus = workflowStatusList?.find(
    (status: { status: string }) => status?.status === initialPayload.status
  );
  const casePayloadType: any = getCasePayloadType(state.activeTabKey) || "";

  // console.log(TAG + " valuesForFilter ", valuesForFilter);
  // console.log(TAG + " startDate ", startDate);
  // console.log(TAG + " endDate ", endDate);

  return (
    <Card
      className="advanceFilter"
      title={"Advance Filter"}
      style={{ width: "100%" }}
      extra={
        <CloseOutlined
          className="text-lg cursor-pointer"
          onClick={() => {
            props.toggleAdvanceFilter();

            dispatch({
              type: casePayloadType,
              payload: defaultPayload,
            });
          }}
        />
      }
    >
      <Form
        layout="vertical"
        name="advance-filter-form"
        form={form}
        onValuesChange={(changedValues, values) => {
          handleFormChange();
          setValuesForFilter(values);
        }}
      >
        <Row gutter={5}>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"Lender"} name="nbfc">
              <Select
                labelInValue
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Lender"
                maxTagCount={0}
                defaultValue={initialNBFC?.clientId}
              >
                {data?.clients?.map(({ clientId, clientName }: any) => {
                  return <Option value={clientId}>{clientName}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"DPD Status"} name="dpdStatus">
              <Select
                labelInValue
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Select DPD Status"
                maxTagCount={0}
                defaultValue={intialDpdStatus?.status}
              >
                {dpdStatusList?.map(({ status }: any) => {
                  return (
                    <Option value={status} key={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item
              label={"Minimum Loan Amount"}
              name="minLoanAmount"
              rules={[
                {
                  validator: validateAmount("minimum loan"),
                },
              ]}
            >
              <Input
                addonBefore={<span>₹</span>}
                placeholder="Enter Minimum Loan Amount"
                minLength={3}
                maxLength={8}
                defaultValue={initialPayload?.minLoanAmount}
              />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item
              label={"Maximum Loan Amount"}
              name="maxLoanAmount"
              rules={[
                {
                  validator: validateAmount("maximum loan"),
                },
              ]}
            >
              <Input
                minLength={3}
                maxLength={8}
                addonBefore={<span>₹</span>}
                placeholder="Enter Maximum Loan Amount"
                defaultValue={initialPayload?.maxLoanAmount}
              />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item
              label={"Minimum Due Amount"}
              name="minDueAmount"
              rules={[{ validator: validateAmount("minimum due") }]}
            >
              <Input
                minLength={3}
                maxLength={8}
                addonBefore={<span>₹</span>}
                placeholder="Enter Minimum Due Amount"
                defaultValue={initialPayload?.minDueAmount}
              />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item
              label={"Maximum Due Amount"}
              name="maxDueAmount"
              rules={[{ validator: validateAmount("maximum due") }]}
            >
              <Input
                minLength={3}
                maxLength={8}
                addonBefore={<span>₹</span>}
                placeholder="Enter Maximum Due Amount"
                defaultValue={initialPayload?.maxDueAmount}
              />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"Status"} name="status">
              <Select
                labelInValue
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Status"
                maxTagCount={0}
                defaultValue={intialWorkflowStatus?.status}
              >
                {workflowStatusList?.map(({ status }: any) => {
                  return (
                    <Option value={status} key={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xxl={4} xl={8} lg={4} md={12} sm={12} xs={12}>
            <Form.Item label={"Last Call Date"} name="lastCallDate">
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={disableFutureDate}
              />
            </Form.Item>
          </Col>

        </Row>
        <Row className="flex justify-end gap-2">
          <Button
            onClick={() => {
              dispatch({
                type: casePayloadType,
                payload: defaultPayload,
              });
              handleFormReset();
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            disabled={isSubmitBtnDisabled}
            onClick={() => {
              dispatch({
                type: casePayloadType,
                payload: payload,
              });
            }}
          >
            OK
          </Button>
        </Row>
      </Form>
    </Card>
  );
}

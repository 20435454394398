import { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, Row, notification } from "antd";

import {
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
} from "../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  UpdateUserProfileTypes,
  updateUserProfile,
} from "../../services/commonController";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const EditProfileModal = ({
  isModalVisible,
  handleProfileModalVisibility,
  userInfo,
}: any) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const queryClient = useQueryClient();
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const { mutate, isPending } = useMutation({
    mutationKey: ["update-user-profile"],
    mutationFn: updateUserProfile,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.message,
        });
        queryClient.invalidateQueries({
          queryKey: ["user-profile"],
        });
        handleProfileModalVisibility();
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Failed",
        description: error.response.data.message,
      });
    },
  });

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleProfileModalVisibility}
      footer={null}
    >
      <Card title="Edit Profile">
        <Form
          {...layout}
          form={form}
          name="update-user"
          style={{ maxWidth: 600 }}
          onFinish={(values) => {
            const payload: UpdateUserProfileTypes = {
              email: values?.emailId,
              firstName: values?.firstName,
              lastName: values?.lastName,
              mobileNo: values?.phoneNumber,
            };
            mutate(payload);
          }}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true },
              {
                validator: validateFirstName,
              },
            ]}
            initialValue={userInfo?.firstName || ""}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true },
              {
                validator: validateLastName,
              },
            ]}
            initialValue={userInfo?.lastName || ""}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="role"
            label="Role"
            initialValue={userInfo?.roleAssign || ""}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="emailId"
            label="Email ID"
            initialValue={userInfo?.email || ""}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true }, { validator: validatePhoneNumber }]}
            initialValue={userInfo?.mobileNo || ""}
          >
            <Input allowClear maxLength={10} />
          </Form.Item>
          <Row className="flex justify-end gap-2">
            <Button onClick={handleProfileModalVisibility}>Cancel</Button>
            <Button
              loading={isPending}
              htmlType="submit"
              type="primary"
              disabled={!form.isFieldsTouched() || !submittable}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

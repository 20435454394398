import axios from "axios";
import {
  AGENT_MANAGER_ACTIVE_CASES_ENDPOINT,
  AGENT_MANAGER_COMPLETED_CASES_ENDPOINT,
  AGENT_MANAGER_PENDING_CASES_ENDPOINT,
  AGENT_MANAGER_CASE_DETAILS_ENDPOINT,
  AGENT_MANAGER_ASSIGN_TO_AGENT_ENDPOINT,
} from "../constants/endpoints";

type AssignToAgentPayloadTypes = {
  email: string;
  procInstanceIds: string[];
  reassignment: boolean;
};
type CasesBodyType = {
  dpdStatus: string;
  endDate: string;
  maxDueAmount: string;
  maxLoanAmount: string;
  minDueAmount: string;
  minLoanAmount: string;
  nbfc: string;
  page: number;
  size: number;
  startDate: string;
  status: string;
};
export function getAgentManagerCaseDetails(id: string, bucketName: string) {
  const endpoint = `${AGENT_MANAGER_CASE_DETAILS_ENDPOINT}${id}&bucketName=${bucketName}`;
  return axios.get(endpoint);
}

export async function getAgentManagerActiveCases(body: any) {
  const res = await axios.post(AGENT_MANAGER_ACTIVE_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}

export async function getAgentManagerPendingCases(body: CasesBodyType) {
  const res = await axios.post(AGENT_MANAGER_PENDING_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}
export async function getAgentManagerCompletedCases(body: CasesBodyType) {
  const res = await axios.post(AGENT_MANAGER_COMPLETED_CASES_ENDPOINT, body);
  if (res.status === 200) {
    return res.data;
  }
}

export function assignToAgentMethod(payload: AssignToAgentPayloadTypes) {
  return axios.post(AGENT_MANAGER_ASSIGN_TO_AGENT_ENDPOINT, payload);
}

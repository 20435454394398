import { Card, Modal, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";

export function PlayAudioMpdal({
  callId,
  caseId,
  isVisible,
  recordingUrl,
  handleModalVisibility,
}: {
  caseId: string;
  callId: string;
  isVisible?: boolean;
  recordingUrl: string;
  handleModalVisibility: () => void;
}) {
  const handleClose = () => {
    handleModalVisibility();
    const audioElement = document.querySelector("audio");
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };

  return (
    <Modal open={isVisible} footer={null} closable={false}>
      <Card
        title="Play Recording"
        extra={
          <CloseOutlined
            className="text-lg cursor-pointer"
            onClick={handleClose}
          />
        }
      >
        <Row className="flex justify-center">
          <ReactAudioPlayer
            key={Math.random()}
            src={recordingUrl}
            controls
            controlsList={"nodownload"}
            autoPlay
            onPlay={(e) => console.log(e)}
            onAbort={(e) => console.log("onAbourt", e)}
          />
        </Row>
      </Card>
    </Modal>
  );
}

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "antd";

import "./App.css";
import { useAuth } from "./contexts/AuthContext";
import { useEffect } from "react";

function RootLayout() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isAuthenticated) {
      if (!location.pathname.includes("/login")) {
        return navigate(location.pathname);
      }
      return navigate("/");
    }
  }, [isAuthenticated, navigate, location.pathname]);

  return (
    <div className={"App"}>
      <Skeleton
        loading={false}
        round
        className="w-80 md:w-full mx-auto pl-10 pr-10"
        paragraph={{ rows: 15 }}
      >
        <Outlet />
      </Skeleton>
    </div>
  );
}

export default RootLayout;
